<template>
  <div>
    <b-form @submit.prevent="submitForm">
      <b-form-group>
        <template #label>
          Price multiplier for {{ exchangeName }} {{ hedgeSide.symbol }}
        </template>
        <b-form-input type="number" min="1" v-model="priceMultiplier" />
      </b-form-group>
      <div class="text-right mt-3">
        <b-button type="button" variant="secondary mr-2" @click="onClickCancel">Cancel</b-button>
        <b-button type="submit" variant="primary">OK</b-button>
      </div>
    </b-form>
  </div>
</template>

<style lang="scss" scoped>

</style>

<script lang="ts">
import {Component, Emit, Prop, Vue} from 'vue-property-decorator';
  import _ from "lodash";
  import BaseComponent from "@/components/BaseComponent";
  import * as constants from "@/constants";

  @Component
  export default class CexHedgingEditPriceMultiplierModal extends BaseComponent {

    @Prop() modalId!: string;
    @Prop() hedgeSide;

    priceMultiplier = "";

    get exchangeName() {
      return constants.exchangeNames[this.hedgeSide.marketExchange];
    }

    mounted() {
      this.priceMultiplier = this.hedgeSide.priceMultiplier;
    }

    onClickCancel() {
      this.$bvModal.hide(this.modalId);
    }

    beforeSubmit() {

    }

    submitForm() {
      this.$bvModal.hide(this.modalId);
      this.done();
    }

    @Emit()
    done() {
      return +this.priceMultiplier;
    }

  }
</script>
