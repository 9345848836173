import { render, staticRenderFns } from "./CexHedging.vue?vue&type=template&id=510be92a&scoped=true"
import script from "./CexHedging.vue?vue&type=script&lang=ts"
export * from "./CexHedging.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "510be92a",
  null
  
)

export default component.exports